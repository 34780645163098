<template>
  <div class="nature-white-box">
    <h1 class="mb-4">Stock value</h1>
    <div
      v-if="warehouse === null || warehouse === undefined"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("productHistory.noWarehouseSelected2") }}
    </div>
    <div v-else-if="data === null">
      <Skeletor
        pill
        height="32"
        class="mb-3"
        :width="Math.random() * 21 + 80 + '%'"
      />
      <Skeletor
        pill
        height="32"
        class="mb-3"
        :width="Math.random() * 21 + 80 + '%'"
      />
      <Skeletor pill height="32" :width="Math.random() * 21 + 80 + '%'" />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-2">Product</div>
        <div class="col-1">Date</div>
        <div class="col-2">Document</div>
        <div class="col-1">VAT</div>
        <div class="col-1">Income</div>
        <div class="col-1">Outcome</div>
        <div class="col-1">Stock</div>
        <div class="col-1">Unit value</div>
        <div class="col-1">Stock value</div>
        <div class="col-1">Sold value</div>
      </div>
      <div v-for="row in data" :key="row.id" class="mb-3">
        <div class="row">
          <div class="col-1 bold-16">{{ row.serial }}</div>
          <div class="col-1 bold-16">{{ row.sku }}</div>
          <div class="col-4 bold-16">{{ row.name }}</div>
        </div>
        <div class="row" v-for="history in row.itemHistories" :key="history.id">
          <div class="col-2"></div>
          <div class="col-1">{{ formatDate(history.created_at) }}</div>
          <div class="col-2" v-if="history.document">
            <a :href="history.base_url + history.event_id" target="_blank">{{
              history.document
            }}</a>
          </div>
          <div class="col-2" v-else>
            <a :href="history.base_url + history.event_id" target="_blank">
              #{{ history.event_id }}
            </a>
          </div>
          <div class="col-1">
            <span v-if="history.vat">
              {{ Math.round(history.vat * 100) / 100 }}%
            </span>
          </div>
          <div class="col-1">
            <span v-if="history.change > 0">
              {{ history.change }}
            </span>
          </div>
          <div class="col-1">
            <span v-if="history.change < 0">
              {{ history.change }}
            </span>
          </div>
          <div class="col-1">{{ history.quantity }}</div>
          <div class="col-1">
            <EditOnClick
              :model-value="{
                amount: history.stock_price,
                currency: history.stock_currency_code,
              }"
              type="price"
              :editable="false"
              :padding="false"
            />
          </div>
          <div class="col-1">
            <EditOnClick
              :model-value="{
                amount: history.stock_price * history.change,
                currency: history.stock_currency_code,
              }"
              type="price"
              :editable="false"
              :padding="false"
            />
          </div>
          <div class="col-1">
            <EditOnClick
              :model-value="{
                amount: history.sell_price,
                currency: history.sell_currency_code,
              }"
              type="price"
              :editable="false"
              :padding="false"
            />
          </div>
        </div>
        <div
          class="row"
          v-for="initial in row.itemOperations"
          :key="initial.id"
        >
          <div class="col-4"></div>
          <div class="col-2 bold-16">End stock</div>
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-1 bold-16">{{ initial.quantity }}</div>
          <div class="col-1 bold-16">
            <EditOnClick
              :model-value="{
                amount: initial.price,
                currency: initial.currency_code,
              }"
              class="bold-16"
              type="price"
              :editable="false"
              :padding="false"
            />
          </div>
          <div class="col-1 bold-16">
            <EditOnClick
              :model-value="{
                amount: initial.price * initial.quantity,
                currency: initial.currency_code,
              }"
              type="price"
              class="bold-16"
              :editable="false"
              :padding="false"
            />
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row">
          <div class="col-4"></div>
          <div class="col-2 bold-16 pt-2">Total end stock</div>
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-1 bold-24">{{ row.product_quantity }}</div>
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";
import { useStore } from "vuex";
import date from "../../modules/date";
import EditOnClick from "../../components/inputs/EditOnClick.vue";

export default {
  name: "StockValueReports",
  components: { EditOnClick },
  data() {
    return {
      store: useStore(),
      data: null,
      hasMore: true,
      loadMore: false,
      page: 1,
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    date() {
      return this.store.state.topBar.date;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    q() {
      if (
        this.store.state.topBar.q === null ||
        this.store.state.topBar.q === undefined
      ) {
        return this.store.state.topBar.q;
      }
      return this.store.state.topBar.q
        .replace(this.product?.serial, "")
        .replace(this.product?.name, "")
        .trim();
    },
  },
  watch: {
    date: {
      deep: true,
      handler() {
        this.page = 1;
        this.loadData();
      },
    },
    warehouse() {
      this.page = 1;
      this.loadData();
    },
    q() {
      this.page = 1;
      this.loadData();
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    this.loadData();
  },
  methods: {
    formatDate(val) {
      return date.format(val, false, false);
    },
    handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (
        scrollTop + clientHeight + 450 >= scrollHeight - 5 &&
        this.hasMore &&
        !this.loadMore
      ) {
        this.loadMore = true;
        this.page++;
        this.loadData();
      }
    },
    loadData() {
      this.loadMore = true;
      http
        .fetch(
          `/analytics/inventory/status?page=${this.page}&warehouse_id=${
            this.warehouse
          }&${this.q ? "&q=" + this.q : ""}${
            this.date[0]
              ? "&from=" + this.date[0].toISOString().split("T")[0]
              : ""
          }${
            this.date[1]
              ? "&to=" + this.date[1].toISOString().split("T")[0]
              : ""
          }`
        )
        .then((data) => {
          for (const row of data.data) {
            if (row.image) {
              row.image = this.mediaServer + "/" + row.image;
            }
            if (this.page > 1) {
              this.data.push(row);
            }
          }
          if (this.page === 1) {
            this.data = data.data;
          }
          if (data.data.length < 50) {
            this.hasMore = false;
          }
          this.loadMore = false;
        });
    },
  },
};
</script>
